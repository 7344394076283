<script>
import LoginRegisterComponent from '../../shared-component/login-register-component/login-register-component.vue'
import router from '../../../router'

export default {
    name: "LoginPage",
    components: {
        LoginRegisterComponent
    },
    props: ['templateMode'],
    created(){
         this.$emit('headerState', 6);
    },
    methods: {
        onSucceed(){
            if (this.$route.query.ad){
                 console.info("on redirige vers l'annonce " + this.$route.query.ad);
                 router.push('/annonce/'+this.$route.query.ad);
            } else {
                  //router.push('MonCompte');
                  router.push('/');
            }
          
            
           
        },
        getBackgroundStyle(){
            
                return "height:570px";
            
        }    

    }
};
</script>

<style lang="scss">
    @import "login-page.scss";
</style>

<template src="./login-page.html"></template>